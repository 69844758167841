
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        
      >
      <el-row :gutter="20" >
        <el-col :span="10">
            <el-form-item label="标题" prop="title" :label-width="'130px'">
                <el-input
                    v-model="formModel.title"
                    placeholder="请输入标题"
                ></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="10">
            <el-form-item label="需打卡天数" prop="days" :label-width="'130px'">
            <el-input
                v-model="formModel.days"
                placeholder="请输入需打卡天数"
                type="number"
                @change="daysChange"
            ></el-input>
            </el-form-item>
        </el-col>
      </el-row>
    <el-row :gutter="20" >
        <el-col :span="10">
            <el-form-item label="总开始时间" prop="startTime" :label-width="'130px'">
                <el-date-picker
                v-model="formModel.startTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                style="width:242px"
                placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
        </el-col>
        <el-col :span="10">
            <el-form-item label="总结束时间" prop="endTime" :label-width="'130px'">
                <el-date-picker
                v-model="formModel.endTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                style="width:242px"
                placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" >
        <el-col :span="10">
            <el-form-item label="描述" prop="des" :label-width="'130px'">
            <el-input
                v-model="formModel.des"
                placeholder="请输入描述"
            ></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="10">
            <el-form-item label="图片" prop="picUrl" :label-width="'130px'">
                <el-upload
                    class="avatar-uploader"
                    name="photoFile"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :headers="headers"
                    :data="uploadData"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                >
                    <img v-if="fileUrl" :src="fileUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
        </el-col>
      </el-row>

        <h3>详情</h3>
        <el-table 
        :data="formModel.detailList" 
        class="compact-table">
            <el-table-column prop="sort" type="index" label="序号" ></el-table-column>
            <el-table-column prop="name"  label="第几天" style="magin">
              <template slot-scope="scope">
                <el-form-item
                    :prop="'detailList.' + scope.$index + '.name'"
                    :rules="ruleValidate['name']"
                  >
                <el-input
                    :value="scope.row.name"
                    v-model="scope.row.name" 
                    type="number"
                    placeholder="请输入第几天"
                    size="mini"
                ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="type"  label="类型" style="magin">
              <template slot-scope="scope">
                <el-form-item
                    :prop="'detailList.' + scope.$index + '.type'"
                    :rules="ruleValidate['type']"
                  >
                    <el-select 
                    v-model="scope.row.type" 
                    placeholder="请选择"  size="mini"
                    @change="handTypeChange(scope)">
                        <el-option
                        v-for="result in typeList"
                        :key="result.value"
                        :label="result.name"
                        :value="result.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column  prop="value"  label="内容" >
              <template slot-scope="scope">
                <el-form-item
                    :prop="'detailList.' + scope.$index + '.value'"
                    :rules="ruleValidate['value']"
                  >
                    <el-select v-if="scope.row.type=='1'"
                    v-model="scope.row.value" 
                    placeholder="请选择" 
                    size="mini">
                        <el-option
                        v-for="result in goodsList"
                        :key="result.id"
                        :label="result.goodsName"
                        :value="result.id"
                        ></el-option>
                    </el-select>
                    <el-input v-if="scope.row.type=='0'"
                    :value="scope.row.value"
                    v-model="scope.row.value" 
                    placeholder="请输入积分数"
                    size="mini"
                ></el-input>
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column label="操作" >
              <template slot-scope="{row}">
                <el-form-item>
                  <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                  <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                </el-form-item>
              </template>
            </el-table-column>

            <div slot="empty">
              <el-button icon="el-icon-circle-plus-outline" :plain="true" @click="handleAdd()">新增</el-button>
            </div>
        </el-table>

      </el-form>
        <div class="el-table__empty-block" v-if="formModel.detailList.length!=0">
          <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import integralActivityApi from "@/api/integral/integralActivityInfo";

import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  data() {
    var validEndTime = (rule, value, callback) => {
      var startTime=this.formModel.startTime;
      var endTime=this.formModel.endTime;
      var diff = this.getDateDiff(startTime,endTime);
      var days = this.formModel.days;
      if (value === "") {
        callback(new Error("结束时间不能为空"));
      } 
      else if (this.formModel.startTime >= value) {
        callback(new Error("结束时间不能小于或等于开始时间"));
      }
      else if (diff < days) {
        callback(new Error("开始时间到结束时间天数不能小于需打卡天数"));
      }
      else{
        callback();
      }
    };
    return {
      formModel: {
            title:"",
            des:"",
            startTime:"",
            endTime:"",
            picUrl:"",
            days:"",
            detailList:[],
            removeDetailList:[],
      },
      ruleValidate: {
        title: [
          { required: true, message: "标题不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "开始时间不能为", trigger: "blur" },
        ],
        endTime: [
          { required: true, validator: validEndTime, trigger: "blur" },
        ],
        days: [
          { required: true, message: "打卡天数不能为空", trigger: "blur" },
        ],
        name:[{ required: true, message: "第几天不能为空", trigger: "blur" }],
        type:[{ required: true, message: "类型未选择", trigger: "blur" }],
        value:[{ required: true, message: "内容不能为空", trigger: "blur" }]
      },
      goodsList:[],
      typeList:[{
        name:"实物",
        value:"1"
      },
      {
        name:"积分",
        value:"0"
      }],
      showDialog: true,
      loading: false,
      submitting: false,
      //上传start
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "personInfo",
      },
      fileUrl: "",
      fileErtificateUrl: "",
      headers: {
        Authorization: getToken(),
      },
      
      //上传end
    };
  },
  created(){

    integralActivityApi.getIntegralGoodList().then((response)=>{
      var jsonData = response.data;
      this.goodsList = jsonData.data;
    });

  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {

          var detailList = self.formModel.detailList;
          var days = self.formModel.days;

          if(days!="0"){
            if(days<detailList.length){
                self.$message.error("需打卡天数不能小于活动详情总天数");
                return;
            }
          }
          

          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return integralActivityApi.add(self.formModel);
            } else {
              return integralActivityApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    //上传start
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.picUrl = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //上传end
    handleAdd() {
        this.formModel.detailList.push({
            sortNo: this.formModel.detailList.length + 1,
            name: "",
            value: "",
            type:"",
        });
    },
    handleDelete(row) {
        var detailList = this.formModel.detailList;

        detailList.splice(detailList.indexOf(row), 1);

        this.formModel.removeDetailList.push(row);
    },
    handleInsert(row) {
        var detailList = this.formModel.detailList;
        var index = detailList.indexOf(row);

        if(index>=0){
            detailList.splice(index+1,0,{
                sortNo: index + 2,
                name:"",
                value:"",
                type:"",
            });
        }
    },
    daysChange(val){
      if(val =="0"){
        this.formModel.endTime="2099-12-31 00:00";
      }
    },
    getDateDiff(startDate,endDate) { 
      var startTime = new Date(Date.parse(startDate.replace(/-/g,   "/" ))).getTime();    
      var endTime = new Date(Date.parse(endDate.replace(/-/g,   "/" ))).getTime();    
      var dates = Math.abs((startTime - endTime))/(1000*60*60*24);    
      return  dates;   
    },
    handTypeChange(scope){
        scope.row.value="";

    }
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return integralActivityApi.create();
      } else {
        return integralActivityApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
            self.formModel = jsonData.data;

            let picUrl = self.formModel.picUrl;
            if (picUrl != null) {
                self.fileUrl =
                picUrl + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
            }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.input-form-main{
    width: 300px;
}
.link-span{
    margin-right: 20px;
}
.el-form-item{
    margin-bottom: 25px;
}
.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.user-panel {
  margin: 10px auto;
}

.compact-table{
    max-height: 300px;
    overflow-y: scroll;
}
</style>